import React from 'react';

import {
	AlertOctagonIcon as MyAlertOctagonIcon,
	AlertTriangleIcon as MyAlertTriangleIcon,
	AudioSpeakerIcon as MyAudioSpeakerIcon,
	AudioSpeakerOffIcon as MyAudioSpeakerOffIcon,
	ArrowRightIcon as MyArrowRightIcon,
	BrbIcon as MyBrbIcon,
	// ChatIcon as MyChatIcon,
	ChangeRoleIcon as MyChangeRoleIcon,
	ChatUnreadIcon as MyChatUnreadIcon,
	CheckIcon as MyCheckIcon,
	ChevronDownIcon as MyChevronDownIcon,
	ChevronLeftIcon as MyChevronLeftIcon,
	ChevronRightIcon as MyChevronRightIcon,
	ChevronUpIcon as MyChevronUpIcon,
	ConnectivityIcon as MyConnectivityIcon,
	CopyIcon as MyCopyIcon,
	CrossIcon as MyCrossIcon,
	DoorIcon as MyDoorIcon,
	EmojiIcon as MyEmojiIcon,
	EndStreamIcon as MyEndStreamIcon,
	ExpandIcon as MyExpandIcon,
	ExitIcon as MyExitIcon,
	EyeCloseIcon as MyEyeCloseIcon,
	EyeOpenIcon as MyEyeOpenIcon,
	GiftIcon as MyGiftIcon,
	GridFourIcon as MyGridFourIcon,
	HandIcon as MyHandIcon,
	HandRaiseFilledIcon as MyHandRaiseFilledIcon,
	HandRaiseIcon as MyHandRaiseIcon,
	HangUpIcon as MyHangUpIcon,
	HeartIcon as MyHeartIcon,
	HomeIcon as MyHomeIcon,
	HorizontalMenuIcon as MyHorizontalMenuIcon,
	InfoIcon as MyInfoIcon,
	MicOffIcon as MyMicOffIcon,
	MicOnIcon as MyMicOnIcon,
	MusicIcon as MyMusicIcon,
	NextIcon as MyNextIcon,
	NotificationsIcon as MyNotificationsIcon,
	PadLockOnIcon as MyPadLockOnIcon,
	PauseIcon as MyPauseIcon,
	PlayIcon as MyPlayIcon,
	PlaylistIcon as MyPlaylistIcon,
	PencilIcon as MyPencilIcon,
	PeopleIcon as MyPeopleIcon,
	PinIcon as MyPinIcon,
	PoorConnectivityIcon as MyPoorConnectivityIcon,
	PrevIcon as MyPrevIcon,
	RecordIcon as MyRecordIcon,
	RemoveUserIcon as MyRemoveUserIcon,
	RocketIcon as MyRocketIcon,
	ShareScreenIcon as MyShareScreenIcon,
	ShieldAlertIcon as MyShieldAlertIcon,
	SearchIcon as MySearchIcon,
	SendIcon as MySendIcon,
	ShrinkIcon as MyShrinkIcon,
	SpeakerIcon as MySpeakerIcon,
	StarIcon as MyStarIcon,
	ThumbsUpIcon as MyThumbsUpIcon,
	ToggleDownIcon as MyToggleDownIcon,
	ToggleUpIcon as MyToggleUpIcon,
	VerticalMenuIcon as MyVerticalMenuIcon,
	VideoOffIcon as MyVideoOffIcon,
	VideoOnIcon as MyVideoOnIcon,
} from "../assets/icons";
import { ReactComponent as Konfetti } from '../assets/sb_icons/Konfetti.svg';
import { ReactComponent as KonfettiOff } from '../assets/sb_icons/Konfetti_off.svg';
import { ReactComponent as PersonIcn } from '../assets/sb_icons/Person.svg';
import { ReactComponent as SettingsIcn } from '../assets/sb_icons/Zahnrad.svg';
import { ReactComponent as ReactionIcn } from '../assets/sb_icons/Reaction.svg';
import { ReactComponent as MyChatIcon } from '../assets/sb_icons/Chat.svg';
import { ReactComponent as WhiteboardIcn } from '../assets/sb_icons/Whiteboard.svg';

import {DoorOpenIcon as MyDoorOpenIcon} from "../assets/icons_mk";

export const ConfettiIcon = Konfetti;
export const ConfettiOffIcon = KonfettiOff;
export const PersonIcon = PersonIcn;
export const SettingsIcon = SettingsIcn;
export const ReactionIcon = ReactionIcn;


export const EmojiIcon = MyEmojiIcon;
export const HeartIcon = MyHeartIcon;
export const ThumbsUpIcon = MyThumbsUpIcon;
export const EyeCloseIcon = MyEyeCloseIcon;
export const EyeOpenIcon = MyEyeOpenIcon;
export const RocketIcon = MyRocketIcon;
export const GiftIcon = MyGiftIcon;

export const MusicIcon = MyMusicIcon;
export const ChatIcon = MyChatIcon;
export const ChatUnreadIcon = MyChatUnreadIcon;

export const MicOnIcon = MyMicOnIcon;
export const MicOffIcon = MyMicOffIcon;
export const SpeakerIcon = MySpeakerIcon;
export const VideoOnIcon = MyVideoOnIcon;
export const VideoOffIcon = MyVideoOffIcon;

export const HandIcon = MyHandIcon;
export const BrbIcon = MyBrbIcon;
export const HandRaiseFilledIcon = MyHandRaiseFilledIcon;

export const ChevronUpIcon = MyChevronUpIcon;
export const ChevronDownIcon = MyChevronDownIcon;
export const ChevronLeftIcon = MyChevronLeftIcon;
export const ChevronRightIcon = MyChevronRightIcon;
export const CrossIcon = MyCrossIcon;
export const PinIcon = MyPinIcon;
export const HorizontalMenuIcon = MyHorizontalMenuIcon;
export const VerticalMenuIcon = MyVerticalMenuIcon;
export const SendIcon = MySendIcon;
export const CheckIcon = MyCheckIcon;
export const ArrowRightIcon = MyArrowRightIcon;
export const ChangeRoleIcon = MyChangeRoleIcon;
export const InfoIcon = MyInfoIcon;
export const PencilIcon = MyPencilIcon;
export const RecordIcon = MyRecordIcon;
export const EndStreamIcon = MyEndStreamIcon;
export const CopyIcon = MyCopyIcon;

export const ExpandIcon = MyExpandIcon;
export const ShrinkIcon = MyShrinkIcon;
export const GridFourIcon = MyGridFourIcon;
export const NotificationsIcon = MyNotificationsIcon;
export const ExitIcon = MyExitIcon;
export const DoorIcon = MyDoorIcon;
export const DoorOpenIcon = MyDoorOpenIcon;
export const AlertOctagonIcon = MyAlertOctagonIcon;
export const AlertTriangleIcon = MyAlertTriangleIcon;
export const ConnectivityIcon = MyConnectivityIcon;
export const PoorConnectivityIcon = MyPoorConnectivityIcon;
export const HangUpIcon = MyHangUpIcon;
export const HomeIcon = MyHomeIcon;

export const AudioSpeakerIcon = MyAudioSpeakerIcon;
export const AudioSpeakerOffIcon = MyAudioSpeakerOffIcon;

export const WhiteboardIcon = WhiteboardIcn;

export const PrevIcon = MyPrevIcon;
export const NextIcon = MyNextIcon;
export const PlayIcon = MyPlayIcon;
export const PauseIcon = MyPauseIcon;
export const PlaylistIcon = MyPlaylistIcon;
export const PeopleIcon = MyPeopleIcon;
export const HandRaiseIcon = MyHandRaiseIcon;
export const SearchIcon = MySearchIcon;
export const RemoveUserIcon = MyRemoveUserIcon;
export const ShareScreenIcon = MyShareScreenIcon;
export const ShieldAlertIcon = MyShieldAlertIcon;
export const StarIcon = MyStarIcon;
export const PadLockOnIcon = MyPadLockOnIcon;
export const ToggleDownIcon = MyToggleDownIcon;
export const ToggleUpIcon = MyToggleUpIcon;
