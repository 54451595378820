import { parsedUserAgent } from "@100mslive/react-sdk";
// import {EventType} from "./eventConfiguration";

/*export const defaultAudioList = [
	{
		name: "Audio1",
		id: "audio1",
		metadata: {
			description: "Artist1",
		},
		url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio1.mp3",
		type: "audio",
	},
	{
		name: "Audio2",
		id: "audio2",
		metadata: {
			description: "Artist2",
		},
		url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio2.mp3",
		type: "audio",
	},
];*/

export const DEFAULT_HLS_ROLE_KEY = "HLS_VIEWER_ROLE";
export const DEFAULT_HLS_VIEWER_ROLE = "hls-viewer";
export const DEFAULT_WAITING_VIEWER_ROLE = "waiting-room";
export const QUERY_PARAM_SKIP_PREVIEW = "skip_preview";
export const QUERY_PARAM_SKIP_PREVIEW_HEADFUL = "skip_preview_headful";
export const QUERY_PARAM_NAME = "name";
export const QUERY_PARAM_VIEW_MODE = "ui_mode";
export const QUERY_PARAM_AUTH_TOKEN = "auth_token";
export const UI_MODE_ACTIVE_SPEAKER = "activespeaker";
export const UI_MODE_GRID = "grid";
export const MAX_TOASTS = 4;
export const MIN_TILESIZE = {width: 70, height: 70};
export const RTMP_RECORD_RESOLUTION_MIN = 480;
export const RTMP_RECORD_RESOLUTION_MAX = 1280;
export const RTMP_RECORD_DEFAULT_RESOLUTION = {
	width: 1280,
	height: 720,
};

export const CHAT_SELECTOR = {
	PEER_ID: "peer_id",
	ROLE: "role",
};

export const APP_DATA = {
	uiSettings: "uiSettings",
	chatOpen: "chatOpen",
	chatSelector: "chatSelector",
	chatDraft: "chatDraft",
	appConfig: "appConfig",
	recordingUrl: "recordingUrl",
	sidePane: "sidePane",
	hlsStats: "hlsStats",
	hlsViewerRole: "hlsViewerRole",
	waitingViewerRole: "waitingViewerRole",
	subscribedNotifications: "subscribedNotifications",
	logo: "logo",
	tokenEndpoint: "tokenEndpoint",
	appLayout: "appLayout",
	hlsStarted: "hlsStarted",
	rtmpStarted: "rtmpStarted",
	recordingStarted: "recordingStarted",
	embedConfig: "embedConfig",
	pdfConfig: "pdfConfig",
	pinnedTrackId: "pinnedTrackId",
	dropdownList: "dropdownList",
	widgetState: "widgetState",
	// MK
	sidePaneLeftState: "sidePaneLeftState",
	sidePaneLeftView: "sidePaneLeftView",
	curMKPollEditId: "curMKPollEditId",
	eventView: "eventView", // is basically "StageView"; changes from EventType (workshop, stage, conference, etc.)
	viewLayout: "viewLayout", // one of the layouts for the video tiles
	showSubtitles: "showSubtitles", // show subtitles or not
	subtitleLanguage: "subtitleLanguage", // the current subtitle language, can be '', 'none', 'de', 'en-US', 'fr', etc.
	audienceTilesPage: "audienceTilesPage", // when displaying audience tiles in multiple pages
};
export const UI_SETTINGS = {
	isAudioOnly: "isAudioOnly",
	isHeadless: "isHeadless",
	maxAudienceTileCount: "maxAudienceTileCount", // was: maxTileCount
	uiViewMode: "uiViewMode",
	showStatsOnTiles: "showStatsOnTiles",
	enableAmbientMusic: "enableAmbientMusic",
	mirrorLocalVideo: "mirrorLocalVideo",
	activeSpeakerSorting: "activeSpeakerSorting",
	hideLocalVideo: "hideLocalVideo",
	// MK
	eventType: "eventType",
	useVirtualBackground: "useVirtualBackground",
	allowNoiseReduction: "allowNoiseReduction",
	showToolbar: "showToolbar",
	showTitlebar: "showTitlebar",
	useChat: "useChat",
	useApplause: "useApplause",
	useConfetti: "useConfetti",
	usePolls: "usePolls",
	allowScreensharing: "allowScreensharing",
	allowRecording: "allowRecording",
	allowStreaming: "allowStreaming",
	useHqAudio: "useHqAudio",
	hiliteDominantSpeaker: "hiliteDominantSpeaker",
	hidePeerNames: "hidePeerNames",
	useMetaActions: "useMetaActions",
	allowWhiteboard: "allowWhiteboard",
	allowUrlSharing: "allowUrlSharing",
	allowTranscription: "allowTranscription",
	useSignLanguageTranslation: "useSignLanguageTranslation",
	useSubtitles: "useSubtitles",
	subtitleSourceLanguage: "subtitleSourceLanguage",
	subtitleLanguages: "subtitleLanguages",
	allowPinning: "allowPinning",
};

export const WIDGET_STATE = {
  pollInView: "pollInView",
  view: "view",
};

export const WIDGET_VIEWS = {
  LANDING: "LANDING",
  CREATE_POLL_QUIZ: "CREATE_POLL_QUIZ",
  CREATE_QUESTIONS: "CREATE_QUESTIONS",
  VOTE: "VOTE",
  RESULTS: "RESULTS",
};

export const SIDE_PANE_LEFT_STATE = {
  pollInView: "pollInView",
  view: "view",
};

export const MKPOLL_VIEWS = {
  LANDING: "LANDING",
  // CREATE_POLL_QUIZ: "CREATE_POLL_QUIZ",
  LIST_POLL_QUIZ: "LIST_POLL_QUIZ",
  VIEW_DETAILS: "VIEW_DETAILS",
  EDIT_POLL_QUIZ: "EDIT_POLL_QUIZ",
  CREATE_QUESTIONS: "CREATE_QUESTIONS",
  VOTE: "VOTE",
  RESULTS: "RESULTS",
};

export const SIDE_PANE_OPTIONS = {
	PARTICIPANTS: "Participants",
	CHAT: "Chat",
	STREAMING: "STREAMING",
	TILES: "TILES",
	SCREEN_TILES: "SCREEN_TILES",
	WIDGET: "WIDGET",
};

export const SUBSCRIBED_NOTIFICATIONS = {
	PEER_JOINED: "PEER_JOINED",
	PEER_LEFT: "PEER_LEFT",
	METADATA_UPDATED: "METADATA_UPDATED",
	NEW_MESSAGE: "NEW_MESSAGE",
	ERROR: "ERROR",
};

export const CREATE_ROOM_DOC_URL =
	"https://www.masterkitchen.de";
	// "https://github.com/100mslive/100ms-web/wiki/Creating-and-joining-a-room";
export const HLS_TIMED_METADATA_DOC_URL =
  "https://www.100ms.live/docs/javascript/v2/how--to-guides/record-and-live-stream/hls/hls-timed-metadata";

export const REMOTE_STOP_SCREENSHARE_TYPE = "REMOTE_STOP_SCREENSHARE";

export const isChrome =
  parsedUserAgent.getBrowser()?.name?.toLowerCase() === "chrome";
export const isFirefox =
  parsedUserAgent.getBrowser()?.name?.toLowerCase() === "firefox";
export const isSafari =
	parsedUserAgent.getBrowser()?.name?.toLowerCase() === "safari";
export const isIOS = parsedUserAgent.getOS()?.name?.toLowerCase() === "ios";
export const isMacOS =
	parsedUserAgent.getOS()?.name?.toLowerCase() === "mac os";
export const isAndroid =
	parsedUserAgent.getOS()?.name?.toLowerCase() === "android";
export const isIPadOS =
	navigator?.maxTouchPoints &&
	navigator?.maxTouchPoints > 2 &&
	navigator?.userAgent?.match(/Mac/);

export const FEATURE_LIST = {
	AUDIO_ONLY_SCREENSHARE: "audioscreenshare",
	AUDIO_PLAYLIST: "audioplaylist",
	VIDEO_PLAYLIST: "videoplaylist",
	EMOJI_REACTION: "emojireaction",
	AUDIO_PLUGINS: "audioplugins",
	VIDEO_PLUGINS: "videoplugins",
	WHITEBOARD: "whiteboard",
	CHANGE_NAME: "changename",
	FULLSCREEN: "fullscreen",
	PICTURE_IN_PICTURE: "pip",
	STARTS_FOR_NERDS: "statsfornerds",
	EMBED_URL: "embedurl",
	BRB: "brb",
	HAND_RAISE: "handraise",
	CHAT: "chat",
	PIN_TILE: "pintile",
};

export const SESSION_STORE_KEY = {
	TRANSCRIPTION_STATE: "transcriptionState",
	PINNED_MESSAGE: "pinnedMessage",
	SPOTLIGHT: "spotlight",
};

export const INTERACTION_TYPE = {
  POLL: "Poll",
  QUIZ: "Quiz",
};

export const QUESTION_TYPE_TITLE = {
  "single-choice": "Single Choice",
  "multiple-choice": "Multiple Choice",
  // "short-answer": "Short Answer",
  // "long-answer": "Long Answer",
	"graphic": "Grafik",
};

export const QUESTION_TYPE = {
  SINGLE_CHOICE: "single-choice",
  MULTIPLE_CHOICE: "multiple-choice",
  // SHORT_ANSWER: "short-answer",
  // LONG_ANSWER: "long-answer",
	"graphic": "graphic",
};

export const PDF_SHARING_OPTIONS = {
  FROM_YOUR_COMPUTER: "From your Computer",
  FROM_A_URL: "From a URL",
};

export const subtitleLanguageMap = {
	"de": "Deutsch",
	"en-US": "English",
	"en-GB": "English",
	"fr": "Français",
	"es": "Español",
	"it": "Italiano",
	"pl": "Polski",
};
