import * as React from 'react';
import { SVGProps } from 'react';

const SvgToggleUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" stroke="currentColor" fill="none" d="M6 16L6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8L18 16C18 19.31 15.31 22 12 22C8.69 22 6 19.31 6 16Z"></path>
    <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" stroke="currentColor" fill="none" d="M9.5 8C9.5 9.38071 10.6193 10.5 12 10.5C13.3807 10.5 14.5 9.38071 14.5 8C14.5 6.61929 13.3807 5.5 12 5.5C10.6193 5.5 9.5 6.61929 9.5 8Z"></path>
  </svg>
);

export default SvgToggleUpIcon;
