import React from "react";

interface ErrorPageParams{
	error: string
}

function ErrorPage({ error }:ErrorPageParams) {
	return (
		<div className="flex justify-center items-center w-full h-full">
			<div className="relative overflow-auto">
				<img
					src="/img/error-bg-dark.svg"
					alt="Error Background"
				/>
				<div className="flex flex-col absolute w-full h-full left-0 top-0 justify-center items-center">
					<h2>404</h2>
					<p className="text-xl">
						{error}
					</p>
				</div>
			</div>
		</div>
	);
}

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;
